import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import { Status } from '@paljs/ui/types';

import React, { useState, useEffect } from 'react';

import { getAllGautras, deleteGautraAPI, editGautraAPI, moveGautraAPI } from '../../actions/gautraAction';

import Auth, { Group } from '../../components/Auth';
// import Socials from '../../components/Auth/Socials';
import SEO from '../../components/SEO';

// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
// import styles from "./complaints.css";

import DataTable from 'react-data-table-component';
// import { DateRangePicker } from 'react-date-range';
// import { format } from 'date-fns'

import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import { SUCCESS, ERROR, API_HOST } from '../../constant/string';

export default function index() {
  const [isLoading, showLoader] = useState(false);
  const [error, setError] = useState('');
  const [originalRows, setOriginalRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState('');

  const [isOpen, setIsOpen] = useState(false);

  const [isDelete, setDelete] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isMove, setMove] = useState(false);

  const tableColumns = [
    {
      name: 'Name',
      selector: (row: { name: any }) => row.name,
      sortable: true,
    },
    {
      name: 'Display Name',
      selector: (row: { display_name: any }) => row.display_name,
      sortable: true,
      grow: 3,
      wrap: true,
    },
    {
      name: 'Action',
      button: true,
      left: true,
      ignoreRowClick: true,
      allowOverflow: true,
      cell: (row: { _id: any; name: any; display_name: any }) => (
        <>
          <Button
            shape={'Round'}
            size={'Tiny'}
            status={'Warning'}
            onClick={() => {
              editGautra(row._id);
            }}
          >
            Edit
          </Button>
          <Button
            shape={'Round'}
            size={'Tiny'}
            status={'Info'}
            onClick={() => {
              const newGautra = window.prompt(`Are you sure you want to Move ${row.name}'s Gautra?`);
              if (newGautra) {
                moveGautra(row._id, row.name, newGautra);
              }
            }}
          >
            Move
          </Button>
          <Button
            id={`button-${row._id}`}
            shape={'Round'}
            size={'Tiny'}
            appearance="outline"
            status={'Danger'}
            onClick={() => {
              if (window.confirm(`Are you sure you want to delete ${row.name}'s?`)) deleteGautra(row);
            }}
            style={{ color: 'Danger' }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  // getting complaints list
  useEffect(() => {
    getGautra();
    // try {
    //   getAllGautras((response: any) => {
    //     let data = response.data.gautras.map((currentValue: { SNO: any }, Index: number) => {
    //       currentValue.SNO = Index + 1;
    //       return currentValue;
    //     });
    //     setOriginalRows(data);
    //     setRows(data);
    //   });
    // } catch (error) {
    //   setError('Something went wrong!');
    //   showLoader(false);
    // }
  }, []);
  const getGautra = async () => {
    const response = await getAllGautras();
    let data = response.data.gautras.map((currentValue: { SNO: any }, Index: number) => {
      currentValue.SNO = Index + 1;
      return currentValue;
    });
    setOriginalRows(data);
    setRows(data);
  };
  const deleteGautra = async (row: any) => {
    try {
      await deleteGautraAPI(row._id);
      setDelete(true);
    } catch (error) {
      setError('Something went wrong!');
      showLoader(false);
    }
  };

  const editGautra = async (rowId: any) => {
    try {
      const response = editGautraAPI(rowId);
      //  (response: any) => {
      setEdit(true);
      // });
    } catch (error) {
      setError('Something went wrong!');
      showLoader(false);
    }
  };

  const moveGautra = async (rowId: any, oldName: string | null, newName: string | null) => {
    try {
      const data = {
        new_gautra: newName,
        old_gautra: oldName,
      };
      await moveGautraAPI(data);
      setMove(true);
    } catch (error) {
      setError('Something went wrong!');
      showLoader(false);
    }
  };

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      do_search();
    }
  };

  const do_search = () => {
    const temp_rows = originalRows.filter((e) => JSON.stringify(e).indexOf(search) >= 0);
    setRows(temp_rows);
  };

  const onDateRangeToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <SEO title="Manage Gautra" />
      <div>
        <div>
          <Row>Gautra</Row>
          <Row>
            <Col breakPoint={{ xs: 12, md: 12 }}>
              <InputGroup fullWidth size="Small" status="Info">
                <input
                  type="text"
                  placeholder="Search..."
                  onChange={(event) => handleInputChange(event)}
                  onKeyDown={(event) => handleKeyDown(event)}
                />
              </InputGroup>
            </Col>
          </Row>
          <DataTable
            title="Gautra List"
            columns={tableColumns}
            data={rows}
            pagination
            striped
            dense
            noHeader
            highlightOnHover
          />
        </div>
      </div>
    </>
  );
}
